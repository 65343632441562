.banner-img {
  margin-top: 60px;
}

.home-page h1 {
  color: gray;
  font-family: "Playfair Display", serif;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
}
.home-page .card {
  background-color: rgba(128, 128, 128, 0.097);
  width: 18rem;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
.home-page .card:hover {
  box-shadow: 0px 0px 8px 4px #ffd9007f;
}
.home-page .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-page .card-price {
  color: green;
  font-weight: bold;
}
.home-page button {
  border-radius: 0;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.home-page {
  color: rgb(90, 89, 89);
}
.card-text {
  margin-top: 8px;
  font-size: 12px;
  color: #e0e0e0;
}
.card-product-price {
  font-weight: 600;
  font-size: 16px;
  color: #5dff3d;
}
.home-page .card img:hover {
  transform: scale(0.9);
}
.product-img {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
  border-radius: 10px 10px 0 0;
}

.product-name {
  background-color: #00033e6c;
  backdrop-filter: blur(3px);
  padding: 3px 8px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
}

/* ==================
filters
============== */
.filters {
  display: block;
}
.filters h4 {
  margin-top: 100px;
  color: gray !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.337);
}

.ant-checkbox-wrapper:first-of-type {
  margin-left: 8px;
}

.ant-checkbox-wrapper {
  margin: 2px;
}
.ant-radio-wrapper {
  margin-bottom: 5px !important;
  margin-left: 10px;
}

.filters button {
  background-color: black;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.loadmore {
  color: green;
  font-weight: bold;
  font-size: 20px !important;
}

@media (max-width: 750px) {
  .sec-filters {
    display: flex;
    overflow: auto;
  }
  .cat-filter {
    width: 200px;
    padding: 0 5px;
  }
  .price-filter {
    width: 300px;
    padding: 0 5px;
  }
  .filters h4 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
  }
  .home-page .card {
    background-color: rgba(128, 128, 128, 0.097);
    width: 100%;
  }
}
