.category {
  flex-wrap: wrap;
}
.category .card {
  background-color: rgba(128, 128, 128, 0.097);
  width: 18rem;
  padding-bottom: 35px;
}
.category .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category .card-price {
  color: #47ff47;
  font-weight: bold;
}
.btn-absolut {
  width: 88%;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  bottom: 15px;
}

.category .card-text {
  color: rgb(138, 138, 138);
}

.category .card img:hover {
  transform: scale(0.9);
}

.category .card-img-top {
  height: 300px !important;
}
