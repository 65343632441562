/* ===================================
========= register page  */
.form-container {
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  flex-direction: column;
}
.login-form {
  position: relative;
  z-index: 5;
}
.form-container form {
  padding: 20px;
  background-color: rgb(14 12 21 / 0.1);
  backdrop-filter: blur(3px);
  border-radius: 20px;
  border: #3f3f3f 1px solid;
}

.form-container form .title {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  color: antiquewhite;
}

.form-container form input {
  border: none;
  border: 1px solid rgba(124, 124, 124, 0.664);
  border-radius: 5px;
}

.form-container form input:hover {
  border: 1px solid #00fb04;
}

.form-container form button {
  width: 250px;
  transition: 0.3s ease-in-out;
}

.forgot-btn {
  background-color: #860000a4 !important;
  border: 0 !important;
  color: #8c8c8c;
}

.forgot-btn:hover {
  color: white !important;
}
