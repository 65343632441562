.cart-page {
  margin-top: 64px;
}

.cart-page h1 {
  padding: 15px !important;

  font-weight: normal;
  background-color: rgba(0, 0, 255, 0.072) !important;
}
.cart-page h1 > p {
  font-size: 20px;
  margin-top: 10px;
}
.card-img-top {
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.cart-page .card {
  padding: 5px !important;
  height: 150px !important;
  margin-bottom: 5px !important;
}
.activePay {
  background-color: #00fb04;
  color: #00fb04;
  font-size: 15px;
}
.cat-card {
  height: 150px;

  backdrop-filter: blur(3px);
  border-radius: 15px 60px 15px 15px;
  border: 1px solid #f1f1f1;
  margin-right: 20px;
  width: 300px;
  font-size: 28px;
  font-weight: 600;
  align-content: center;
  justify-items: center;
}
.cat-link {
  padding: 40px;
  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 99;
}
.cat-link:hover {
  color: #00fb04;
}
@media (max-width: 750px) {
  .card-img-top {
    height: 250px;
  }
}
