@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sora: "Sora", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  color-scheme: dark;
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Code Pro", monospace;
}

@layer base {
  body {
    @apply font-sans bg-n-8 text-n-1 text-base;
  }
}

.rotate-45 {
  @apply rotate-[45deg];
}

.rotate-90 {
  @apply rotate-[90deg];
}

.rotate-135 {
  @apply rotate-[135deg];
}

.rotate-180 {
  @apply rotate-[180deg];
}

.rotate-225 {
  @apply rotate-[225deg];
}

.rotate-270 {
  @apply rotate-[270deg];
}

.rotate-315 {
  @apply rotate-[315deg];
}

.rotate-360 {
  @apply rotate-[360deg];
}

.-rotate-45 {
  @apply rotate-[-45deg];
}

.-rotate-90 {
  @apply rotate-[-90deg];
}

.-rotate-135 {
  @apply rotate-[-135deg];
}

.-rotate-180 {
  @apply rotate-[-180deg];
}

.-rotate-225 {
  @apply rotate-[-225deg];
}

.-rotate-270 {
  @apply rotate-[-270deg];
}

.-rotate-315 {
  @apply rotate-[-315deg];
}

.-rotate-360 {
  @apply rotate-[-360deg];
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #00fb04;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.typeAnim-dev {
  font-size: 18px;
  background-color: #00021c89;
  backdrop-filter: blur(5px);
  padding: 20px;
  border: #3f3f3f 1px solid;
  border-radius: 15px;
  margin: 10px 20px;
}
.hero-div {
  border: 1px solid #3a3a3a42;
}
.menu-btn {
  font-size: 25px;
  color: #f4f4f4;
}
.dropdown-menu {
  position: relative;
  background-color: #dfdfdf49;
  backdrop-filter: blur(10px);
  top: 90px;
  border: 1px solid #cbcbcb;
  border-radius: 18px;
  margin-right: 25px;
  padding: 15px;
  z-index: 90;
  width: 250px;
}

.nav-item {
  display: flex;
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 8px;
}

.nav-link {
  font-weight: 300 !important;
  color: #f4f4f4;
  padding: 0;
  margin: 0;
}
.nav-link:hover {
  color: #00fb04;
}
.menu-items {
  margin: 5px;
  padding: 5px;
  display: flex;
  color: #f4f4f4;
  font-weight: 600;
  border-radius: 8px;
  transition: 0.3 ease-in-out;
}
.menu-items:hover {
  background-color: #00ff003a;
}
.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
  color: #f4f4f4;
}

.logo {
  width: 90px;
}

.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(
    90deg,
    rgba(173, 0, 176, 0.6) 0%,
    rgba(63, 67, 97, 0.3) 45%,
    rgba(0, 251, 4, 0.6) 80%
  );
  backdrop-filter: blur(3px);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  z-index: 999;
}

.scroll-top-button.visible {
  opacity: 1;
}
.scroll-top-button:hover {
  transform: scale(1.1);
  background: #00fb04;
  color: #000000;
}

.home-card {
  width: auto;
  max-width: 85%;
  background-color: #06002849;
  backdrop-filter: blur(3px);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-shadow: #201c2e 0px 0px 10px 2px;
}
.home-card-content {
  color: #f4f4f4;
}

.contact-text-bg {
  background-color: #06002a49;
  backdrop-filter: blur(3px);
  border-radius: 20px;
  padding: 20px;
}
.btns-form-div {
  display: block;
  justify-content: center;
}
.switch-link {
  margin-top: 25px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.home-cat-div {
  overflow: auto;
}

.home-cat-card {
  width: 320px;
  min-width: 260px;
  margin: 20px;
  padding: 25px;
  border-radius: 20px;
  border: 1px solid #cbcbcb;
}
.home-product-card {
  display: flex;
  border-radius: 8px;
  width: auto;
  flex-direction: column;
  align-items: center;
}
.home-card h1 {
  color: #cbcbcb;
  font-weight: 600;
}
.home-card-btns {
  display: flex;
  justify-content: center;
}
.search-product-card {
  width: 300px;
  min-width: 260px;
  background-color: #06002849;
  backdrop-filter: blur(3px);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-shadow: #201c2e 0px 0px 10px 2px;
}
.main-btn {
  border: 2px solid #2348ff;
  color: #2348ff;
}
.main-btn:hover {
  background-color: #2348ff;
  color: #f4f4f4;
}
.sec-btn:hover {
  background-color: #199600;
  color: #f4f4f4;
}
.sec-btn {
  border: 2px solid #199600;
  color: #199600;
}
.btn {
  text-decoration: none;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 8px;
  margin: 5px;
}
.login-img-div {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fefefe;
}
.login-img-div img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.search-form {
  margin-right: 10px;
  margin-top: 4px;
  color: #f4f4f4;
}
.search-form input {
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  background-color: #090028bf;
}

/* ===== Admin ====== */

.admin-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 200px;
  padding: 20px;
  background-color: rgb(14 12 21 / 0.1);
  backdrop-filter: blur(3px);
  box-shadow: #00380177 0px 0px 10px 2px;
  border-right: 1.5px solid rgb(37 33 52);
}

.dashboard-menu {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
.dropdown-item {
  border-bottom: 2px solid #0d014e49;
  padding: 10px 8px;
  transition: 0.3s ease-in-out;
}
.dropdown-item:hover {
  background-color: #0d014e49;
  color: #000000;
}
.menu-item {
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: rgb(147, 147, 147);
  transition: color 0.3s ease;
  border-radius: 10px;
  border: 1px solid transparent;
}

.menu-item:hover {
  color: #00fb04;
  border: 1px solid #00fb04;
}

.main-container {
  margin: 100px 5px;
  height: 100%;
  padding-bottom: 50px;
}
.menu-item.active {
  color: #fff;
  background-color: #00fb04b4;
}
.table-container {
  margin-left: 215px;
  margin-right: 15px;
  padding: 20px;
  overflow: auto;
  width: auto;
  background-color: rgb(14 12 21 / 0.1);
  backdrop-filter: blur(3px);
  border: #3f3f3f 1px solid;
  border-radius: 10px;
  box-shadow: #00380177 0px 0px 5px 2px;
  color: #fafafa;
}
/* User.js */
.table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid transparent;
  border-image: linear-gradient(45deg, #00fb04, #00c6ff);
  border-image-slice: 1;
  border-radius: 10px;
  box-shadow: 0 4px 8px #9e9e9ec9;
  overflow: auto;
  color: #fafafa;
}

.table thead th {
  background-color: #00c003;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #00c003;
}

.table tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.table tbody tr:hover {
  background-color: #00ff001a;
}

.user-form {
  width: 450px;
}
.about {
  margin-bottom: 100px;
  height: 100%;
}

/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}
/* ========================================= */
/* =========contact us ========= */
.contactus {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pages-img-div {
  width: 40%;
}
.pages-text-div {
  width: 55%;
  padding: 0 0 25px;
}
.pages-text-div h1 {
  border-radius: 10px;
  background-color: #000000;
  color: #f4f4f4;
  text-align: center;
  padding: 8px 12px;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}
/*
.cat-btn {
  padding: 40px 0px;
  font-size: 24px;
  text-transform: uppercase;
}
.cat-btn:hover {
  background-color: #434343;
  color: white;
}
*/
/* ================
====dashboard
=================== */

.dashboard {
  margin-top: 100px !important;
}
.cart-page {
  padding: 50px 15px;
}
.logout-btn {
  background-color: #ec3300;
  border-radius: 10px;
  margin: 0 10px;
  width: 90%;
}
.min-amount {
  padding: 4px 8px;
  background-color: #be2900;
  border-radius: 15px;
  font-weight: 600;
  color: #f4f4f4;
  font-size: 13px;
  text-align: center;
}

.ccp-payment-info,
.card-payment-info,
.cash-payment-info,
.crypto-payment-info,
#payment-form {
  animation: fadeSlide 0.5s forwards;
  text-align: center;
  padding-inline: 15px;
  padding-bottom: 20px;
  position: relative;
}
.payment-title {
  text-align: center;
  margin-top: 20px;
  font-size: 22px;
  font-weight: 700;
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.crypto-icons-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}
.crypto-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.cryptoIco {
  position: relative;
  pointer-events: none;
}
.crypto-name {
  position: absolute;
  cursor: pointer;
  padding-top: 90px;
}

.qr-code-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-content {
  z-index: auto;
  position: relative;
  width: 90%;
  display: block;
  align-content: end;
  padding: 20px;
  background-color: rgba(0, 1, 30, 0.952);
  backdrop-filter: blur(8px);
  border-radius: 15px;
}

.qr-code-image {
  position: relative;
  display: flex;
  width: 150px;
  height: 150px;
  padding: 5px;
  border-radius: 8px;
}
.crypto-address,
.iban {
  background-color: #0f0227;
  color: #f0f0f0;
  padding: 8px;
  margin: 5px;
  border-radius: 12px;
  border: #bd1fcb solid 1px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
}
.copy-btn {
  font-size: 22px;
  padding: 8px 12px;
  background-color: #02a802;
  border-radius: 40%;
  margin-bottom: 12px;
}
.map-btn {
  border: 1px solid #0f0227;
  border-radius: 8px;
  padding: 4px 10px;
  margin: 5px;
  transition: 0.3s ease-in-out;
  border-bottom: 2px solid #00a703;
}
.map-btn:hover {
  color: #f0f0f0;
  background-color: #00a703;
}
.social-contact {
  display: flex;
  align-content: center;
  justify-content: center;
}
.so-icon {
  color: #00fb04;
  padding-inline: 10px;
  margin-top: 5px;
  font-size: 45px;
  transition: 0.3s ease-in-out;
}
.so-icon:hover {
  color: #bd1fcb;
}

.blog-card {
  border: 1px solid #cbcbcb;
  border-radius: 5px 35px 5px 5px;
  cursor: pointer;
  display: block;
  justify-content: space-between;
}
.blog-card img {
  border-radius: 0 35px 0 0;
}
.blog-card-content {
  position: relative;
  display: block;

  padding: 30px;
  justify-content: space-between;
}
.blog-card-footer {
  display: flex;
  justify-content: space-between;
}
.blog-sec1 {
  position: relative;
  display: flex;
  margin: 20px;
  padding: 20px;
}
.blog-img-div {
  display: flex;
  width: 40%;
}
.blog-page-img {
  width: auto;
  height: min-content;
  margin: 15px;
  border-radius: 25px;
}
@media (max-width: 950px) {
  .blog-sec1 {
    position: relative;
    display: block;
    margin: 1px;
    padding: 5px;
  }
  .blog-img-div {
    width: 100%;
  }
  .blog-page-img {
    margin: 1px;
    border-radius: 25px;
  }
}
@media (max-width: 750px) {
  .admin-menu {
    margin: 0;
    padding: 0;
    padding-top: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 98;
  }

  .typeAnim-dev {
    font-size: 12px;
    padding: 10px;
    margin: 8px 10px;
  }
  .nav-item {
    margin-bottom: 10px;
  }

  .dashboard-menu {
    margin: 0;
    padding: 15px;
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 100%;
  }

  .menu-item {
    font-size: 12px;
    font-weight: 600;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid #333;
    width: 100%;
    height: auto;
  }
  .main-container {
    margin-top: 200px;
  }
  .table-container {
    margin: 10px;
    overflow: auto;
  }
  .user-form {
    width: 95%;
  }
  .pages-img-div {
    width: 80%;
  }
  .pages-text-div {
    width: 100%;
  }

  .login-img-div img {
    transform: translateX(-280px);
    height: 100%;
    width: auto;
  }
}
